import moment from "moment";
import { defineStore } from "pinia";
import { ref } from "vue";
import { DateRangeSelection } from "../Modal/DateRange";
import { SelectLocationsSelection } from "../Modal/SelectLocations";
import { TaskType } from "seedgreen-shared/models/TaskType";
import { ITask } from "seedgreen-shared/models/ITask";
import { TaskStatus } from "seedgreen-shared/models/TaskStatus";

export const TasksSortOptions: Record<string, (_a: ITask, _b: ITask) => -1 | 0 | 1> = {
	dateAsc: (a, b) => {
		return a.displayDate > b.displayDate ? 1 : -1;
	},
	dateDsc: (a, b) => {
		return a.displayDate > b.displayDate ? -1 : 1;
	},
	status: (a, b) => {
		return a.status > b.status ? 1 : -1;
	},
	type: (a, b) => {
		return a.type > b.type ? 1 : -1;
	},
	createdDateAsc: (a, b) => {
		return a.createdDate > b.createdDate ? 1 : -1;
	},
	createdDateDsc: (a, b) => {
		return a.createdDate > b.createdDate ? -1 : 1;
	},
};

export type TaskFilters = {
	type: (keyof typeof TaskType | "all")[];
	status: (TaskStatus | "all")[];
	date?: DateRangeSelection;
	sort: keyof typeof TasksSortOptions;
	location?: SelectLocationsSelection & {
		selection: number[] | string[]; // Serializable
	};
};
/**
 * We show the task filters in different views (planting details, tasks list, ...), and
 * they should have different saved values to avoid unexpected filtering.
 */
export type FilterContext = "tasks.list" | "plantings.details";
export type AllTaskFilters = Record<FilterContext, TaskFilters>;

const getDefaultFilters = (): AllTaskFilters => ({
	"tasks.list": {
		status: ["all"],
		type: ["all"],
		sort: "dateDsc",
		date: { selectionType: "date", date: moment().format("YYYY-MM-DD") },
	},
	"plantings.details": {
		status: ["all"],
		type: ["all"],
		sort: "dateDsc",
		// default date is none, because we want to see all of a planting's tasks
	},
});

export const useTasksStore = defineStore(
	"tasks",
	() => {
		const tasksFilters = ref(getDefaultFilters());
		function mergeFilterChanges(patch: Partial<AllTaskFilters>) {
			tasksFilters.value = { ...tasksFilters.value, ...patch };
		}

		// TODO: This is totally not type safe. This was from the old js code, should be rethought.
		function filterChanged(filter: keyof TaskFilters, value: ["all"] | unknown, filtersContext: FilterContext) {
			const updated = { ...tasksFilters.value[filtersContext] };

			if (Array.isArray(value)) {
				// If an option was selected while "all" was already selected, de-select "all"
				if (
					Array.isArray(updated[filter]) &&
					updated[filter].includes("all") &&
					value.includes("all") &&
					value.length > updated[filter].length
				)
					(updated[filter] as any[]) = value.filter((x) => x !== "all");
				// If no options are selected, select "all"
				else if (!value.length) (updated[filter] as any[]) = ["all"];
				// If all was selected, de-select everything else
				else if (Array.isArray(updated[filter]) && !updated[filter].includes("all") && value.includes("all"))
					(updated[filter] as any[]) = ["all"];
				// Otherwise, commit any actual updates
				else {
					(updated[filter] as any[]) = value;
				}
			} else {
				(updated[filter] as any) = value;
			}

			mergeFilterChanges({
				[filtersContext]: updated,
			});
		}

		function $reset() {
			tasksFilters.value = getDefaultFilters();
		}

		return { $reset, tasksFilters, filterChanged };
	},
	{
		persist: true,
	},
);
